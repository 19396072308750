<template>
  <div class="py-3 d-flex justify-content-center"
       style="background-color: #f2f2f2;    border-bottom: #428bca 10px solid;">
    <div class="w1180 bg-white p-4 my-4">
      <CasesHeader :c_title="info.title" :c_date="info.date"/>

      <div class="x12 text-big">
        <p>我姓吕，今年21岁，目前在美国一所大学读书。我的父母都是大学教授，因此对我的教育非常重视。为了能让我受到更好的教育，拥有有更广阔的发展空间，在高一结束后他们就将我送往美国继续读高中了。为了使我能够在大学毕业后继续按照我想要的方式发展而不受任何签证等原因的制约，他们决定帮我办理美国移民。</p>
        <p>父母去听了几场移民咨询会，终选定了跃迁的纽约四季酒店项目。经过接触，他们对跃迁的专业服务非常满意。因为我还在校读书，并没有收入积累，所以跃迁建议我采用了父母赠与的方式，父母通过将名下一套房产抵押给银行获得了借款，并将这笔资金赠与给我办理移民。整个过程中，跃迁给予了非常细致和耐心的指导：事前，根据我们的情况给予清楚明确的清单，指导我们收集符合美国移民局要求的资金来源证明文件；事中，帮我们联系了多家银行办理贷款事宜；事后，每月向我们发布申请进度图、施工进度，使我们在等待中也可以不用费力气去关注相关信息。</p>
        <p>我期间还去纽约四季酒店项目考察了一下，非常满意，之后没多久我获批通过485身份转换拿到了美国绿卡。我很感谢父母对我的付出，也感谢跃迁的帮助！</p>
        <p>
          <img loading="lazy" class="aligncenter" src="@/assets/Cases/7/1.jpg">
        </p>
      </div>

      <CasesFooter class="mt-5" :index="info.index"/>
    </div>
  </div>

</template>

<script>
import CasesHeader from "@/components/Cases/CasesHeader";
import CasesFooter from "@/components/Cases/CasesFooter";

export default {
  name: "Cases9",
  components: {CasesFooter, CasesHeader},
  data() {
    return {
      info: this.$route.meta,
    }
  }
}
</script>

<style scoped>
.aligncenter {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

a {
  color: #428bca;
  text-decoration: none;
}
</style>